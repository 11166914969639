<template>
  <UiLink
    :class="
      cn(
        'article-card group relative flex h-80 flex-1 flex-col overflow-hidden rounded-lg transition-shadow',
        props.class
      )
    "
    :to="article.href"
    dir="ltr"
  >
    <NuxtImg
      class="absolute inset-0 h-full w-full rounded-t-lg"
      :src="getImageUrl(article.bg_image)"
      :alt="article.title"
      loading="lazy"
    />

    <div class="z-1 flex flex-1 flex-col p-5">
      <div class="flex flex-col gap-4 text-white">
        <div class="flex items-center gap-2">
          <NuxtImg
            class="size-6 rounded-full border-black/15"
            :src="getImageUrl(article.author_image)"
            :alt="article.author"
            loading="lazy"
          />

          <span class="text-xs/5">{{ article.author }}</span>
        </div>

        <p class="text-lg/6 font-semibold">{{ article.title }}</p>
      </div>

      <div class="mt-top mt-auto flex items-center text-xs/5 text-white/80 underline-offset-4">
        <span>{{ article.date }}</span>

        <UiIconCircleSeparator spaced />

        <span>{{ article.time_to_read }}</span>
      </div>
    </div>
  </UiLink>
</template>

<script setup lang="ts">
import type { _GlobalComponents } from "#components"

import { BlogArticle } from "./Blog.vue"

const props = defineProps<{
  class?: ClassValue
  article: BlogArticle
}>()
</script>

<style lang="scss" scoped>
.article-card {
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 5px 12px -2px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow:
      0px 0px 0px 1px rgba(0, 0, 0, 0.01),
      0px 5px 12px -2px rgba(0, 0, 0, 0.1);
  }
}

// .swiper-card {
//   width: 240px;
// }
</style>

<i18n lang="json">
{
  "he": {},
  "en": {}
}
</i18n>
