export function useIsIntersected() {
  const containerRef = ref<HTMLElement | null>(null)
  const isIntersected = ref(false)

  useIntersectionObserver(
    containerRef,
    ([{ isIntersecting }]) => {
      if (isIntersected.value) return
      isIntersected.value = isIntersecting
    },
    { rootMargin: "200px" }
  )

  return { containerRef, isIntersected }
}
