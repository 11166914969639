<template>
  <div :class="cn('card-shuffle-container w-full', props.class)" :key="$i18n.locale">
    <TransitionGroup class="flex" tag="div" name="card-shuffle">
      <template v-for="(item, index) in cloned" :key="get(item, props.idField || 'id')">
        <slot
          name="item"
          :item
          :index
          :className="cn('card-shuffle', { 'card-shuffle-animate-first': index === 0 && animated })"
        />
      </template>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts" generic="T = Record<string, any>">
import { get } from "lodash-es"

export interface LandingHomeMovingCardsListProps<T> {
  list: T[] | undefined
  class?: ClassValue
  idField?: keyof T | (string & {})
}

const props = defineProps<LandingHomeMovingCardsListProps<T>>()

const animated = ref(false)
const listAsRef = toRef(props, "list")
const { cloned, sync } = useCloned<T[]>(listAsRef, { deep: true })

const { locale } = useI18n()

watch(listAsRef, sync, { deep: true })
watch(locale, () => (animated.value = false))

const reorderLastToFirst = () => {
  const lastCard = cloned.value.pop()

  if (lastCard !== undefined) {
    cloned.value.unshift(lastCard)
  }

  if (!animated.value) animated.value = true
}

defineExpose({
  reorderLastToFirst,
  reorderedList: cloned,
})
</script>

<style lang="scss">
.card-shuffle-container {
  --total-time: 1.6s;
  --child-time: 0.33s;
  --cubic-bezier: cubic-bezier(0.17, 0.67, 0.32, 0.92);
}

// Animate in
@keyframes cardShuffleAnimateFirstIn {
  0% {
    opacity: 0;
    z-index: -1;
    transform: scale(0.9) translateX(2rem);
  }

  100% {
    opacity: 1;
    z-index: -1;
    transform: scale(1) translateX(0%);
  }
}

.card-shuffle-move {
  z-index: 1;
  transition: all var(--total-time) var(--cubic-bezier);
  perspective: 1000px;

  --decrease-base: -1;
  .app-ltr & {
    --decrease-base: 1;
  }

  // Animate out
  &:first-child {
    opacity: 0;
    z-index: -1;
    transform: scale(1.03) translateX(calc(calc(100% + 2rem) * var(--decrease-base)));
    transition: all var(--child-time) var(--cubic-bezier);
  }
}

.card-shuffle-animate-first {
  animation: cardShuffleAnimateFirstIn calc(var(--child-time) * 3) calc(var(--child-time) * 1)
    var(--cubic-bezier);
}
</style>
