<template>
  <section class="bg-neutral-100 py-10" aria-labelledby="blog-heading">
    <div class="container grid grid-cols-3 gap-5 md:flex md:flex-col md:gap-10">
      <LandingHomeSectionBlogHeader
        header="BLOG"
        title="START FINQING DIFFERENTLY ABOUT INVESTING"
        seeAllHref="https://blog.finqai.com/"
        labelledby="blog-heading"
      />

      <SwiperList class="flex-grow-1 col-span-2" :list="articles" :item-width="250" :enable="isMobile">
        <template #item="{ item, className }">
          <LandingHomeSectionBlogCard :article="item" :class="className" />
        </template>
      </SwiperList>
    </div>
  </section>
</template>

<script setup lang="ts">
export interface BlogArticle {
  date: string
  title: string
  href: string
  bg_image: string
  author: string
  author_image: string
  time_to_read: string
}

const { isMobile } = useDisplay()

const articles: BlogArticle[] = [
  {
    date: "January 22, 2024",
    time_to_read: "6 min",
    title: "The importance of transparency in finance: Guiding principles for modern investors",
    author: "Robert Samuels",
    author_image: "homepage/avatar-robert.png",
    href: "https://blog.finqai.com/articles/financial-transparency-investing-guiding-principles",
    bg_image: "homepage/article-image-blog-1.png",
  },
  {
    date: "February 26, 2024",
    time_to_read: "9 min",
    title: "The myth of objectivity in financial services: A FINQ perspective",
    author: "Robert Samuels",
    author_image: "homepage/avatar-robert.png",
    href: "https://blog.finqai.com/articles/objectivity-in-finance",
    bg_image: "homepage/article-image-blog-2.png",
  },
  {
    date: "November 22, 2023",
    time_to_read: "7 min",
    title: "Navigating market volatility: Lessons from past crashes and the power of FINQ’s AI",
    author: "Eldad Tamir, CEO & Founder",
    author_image: "homepage/avatar-eldad.png",
    href: "https://blog.finqai.com/articles/market-crash-insights-and-finq-ai-strategy",
    bg_image: "homepage/article-image-blog-3.png",
  },
]
</script>

<style lang="scss" scoped>
.article-card {
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 5px 12px -2px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow:
      0px 0px 0px 1px rgba(0, 0, 0, 0.01),
      0px 5px 12px -2px rgba(0, 0, 0, 0.1);
  }
}

.swiper-card {
  min-width: 185px;
  width: 100%;
  max-width: 250px;
  @screen md {
    min-width: 250px;
    max-width: 250px;
  }
}
</style>
