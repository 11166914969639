<template>
  <div
    class="opacity-0 transition-opacity delay-500 group-hover:opacity-100 group-hover:delay-0 data-[state=waiting]:opacity-100"
    :style="hideModel ? { opacity: 0 } : null"
    :data-state="dataState"
  >
    <button
      class="z-1 absolute left-1/2 top-1/2 grid aspect-square -translate-x-1/2 -translate-y-1/2 place-content-center rounded-full p-3"
      :style="{ backgroundColor: '#5d5f5f' }"
      :data-state="dataState"
      @click.stop="emit('togglePlay')"
    >
      <UiSpinner class="text-white" v-if="waiting" />
      <i v-else v-auto-animate="autoAnimateFade">
        <IconPlay class="translate-x-px text-white" v-if="!playing" :size="28" />
        <LucidePause class="translate-x-px text-white" v-else :size="28" />
      </i>
    </button>

    <LandingHomeSectionVideosScrubber
      v-if="!(currentTimeModel === 0 && waiting) || seeking"
      v-model:model-value="currentTimeModel"
      :data-state="dataState"
      :max="duration"
      :end-buffer="endBuffer"
      :waiting
    >
      <template #default="{ position, pendingValue }">
        <span
          class="z-60 absolute bottom-0 mb-6 -translate-x-1/2 transform rounded bg-white/80 px-2 py-1 text-xs tabular-nums text-black"
          :style="{ left: position }"
        >
          {{ formatDuration(pendingValue) }}
        </span>
      </template>
    </LandingHomeSectionVideosScrubber>
  </div>
</template>

<script setup lang="ts">
import { LucidePause } from "lucide-vue-next"

const props = defineProps<{
  duration: number
  seeking: boolean
  waiting: boolean
  playing: boolean
  buffered: [number, number][]
}>()
const emit = defineEmits(["togglePlay"])
const currentTimeModel = defineModel<number>()
const hideModel = defineModel<boolean>("hide", { default: false })
const dataState = computed(() => (props.waiting ? "waiting" : props.playing ? "playing" : "paused"))

const endBuffer = computed(() =>
  props.buffered.length > 0 ? props.buffered[props.buffered.length - 1][1] : 0
)
const formatDuration = (seconds: number) => new Date(1000 * seconds).toISOString().substr(14, 5)
</script>
