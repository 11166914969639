<template>
  <section class="container flex justify-center gap-5">
    <SwiperList
      :list="videos"
      :item-width="320"
      @slideChange="() => (currentPlayingVideo = null)"
      :enable="isMobile"
    >
      <template #item="{ item: video, index, className }">
        <LandingHomeSectionVideosCard
          class="swiper-card"
          v-model:playing="currentPlayingVideo"
          :class="className"
          :index
          :video
        />
      </template>
    </SwiperList>
  </section>
</template>

<script setup lang="ts">
import { SwiperList } from "#components"

export interface HomepageVideo {
  title: string
  thumbnail: string
  video: string
}

const { isMobile } = useDisplay()
const { tmsafe } = useI18nUtils({ useScope: "local" })
const currentPlayingVideo = ref<Nullable<number>>(null)
const videos = computed(() => tmsafe("videos") as HomepageVideo[])
</script>

<style lang="scss" scoped>
:deep(.swiper-card) {
  height: 480px;
  min-width: 250px;
  width: 100%;
  @screen md {
    min-width: 320px;
    max-width: 250px;
  }
}
</style>

<i18n lang="json">
{
  "he": {
    "videos": [
      {
        "title": "איך FINQ נולדה",
        "thumbnail": "homepage/video-1.png",
        "video": "https://finqfinance-dev-public.s3.eu-west-1.amazonaws.com/How_FINQ_came_to_be.mp4"
      },
      {
        "title": "איך עובדת הבינה המלאכותית המנצחת את השוק",
        "thumbnail": "homepage/video-2.png",
        "video": "https://finqfinance-dev-public.s3.eu-west-1.amazonaws.com/How_the_AI_that_beat_the_market_works.mp4"
      },
      {
        "title": "כיצד FINQ שונה",
        "thumbnail": "homepage/video-3.png",
        "video": "https://finqfinance-dev-public.s3.eu-west-1.amazonaws.com/How_is_FINQ_different.mp4"
      }
    ]
  },
  "en": {
    "videos": [
      {
        "title": "How FINQ came to be",
        "thumbnail": "homepage/video-1.png",
        "video": "https://finqfinance-dev-public.s3.eu-west-1.amazonaws.com/How_FINQ_came_to_be.mp4"
      },
      {
        "title": "How the AI that beat the market works",
        "thumbnail": "homepage/video-2.png",
        "video": "https://finqfinance-dev-public.s3.eu-west-1.amazonaws.com/How_the_AI_that_beat_the_market_works.mp4"
      },
      {
        "title": "How is FINQ different",
        "thumbnail": "homepage/video-3.png",
        "video": "https://finqfinance-dev-public.s3.eu-west-1.amazonaws.com/How_is_FINQ_different.mp4"
      }
    ]
  }
}
</i18n>
