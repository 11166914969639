export function usePreloadImage() {
  const preloadedImages = ref<string[]>([])

  function preloadImage(src: string) {
    if (preloadedImages.value.includes(src)) return

    const img = new Image()
    img.src = getImageUrl(src)
    img.onload = () => preloadedImages.value.push(src)
  }

  return { preloadedImages, preloadImage }
}
