import type { PlanCode, PricingProductEnum } from "./../../../../packages/pricing/types/index"
import type { RoutesNamesList, TypedRouteProps } from "./routes-utils"

export interface LandingCtaProperties {
  label?: string
  action?: { to?: TypedRouteProps; onClick?: () => void }
  hasProductToAccess?: boolean
}

export function useHomepageCta() {
  const login = useLogin()
  const user = useUser()

  const { t } = useI18n({ useScope: "global" })

  const cfg = useAppConfig()

  const productToAccess = computed(() => {
    if (!user.isAuthenticated.value) {
      return undefined
    }

    if (cfg.features?.heroCtaPriorities) {
      for (const properties of cfg.features?.heroCtaPriorities) {
        if (
          user.canAccessProduct(
            properties.productId as PricingProductEnum,
            [properties.planCode] as PlanCode[]
          )
        ) {
          return properties as {
            productId: PricingProductEnum
            planCode: PlanCode
            route: RoutesNamesList
          }
        }
      }
    }

    return undefined
  })

  const heroCtaAction = computed(() => {
    if (!user.isAuthenticated.value) {
      return {
        onClick: () => login.open({ success: () => null }, "signup"),
      }
    }

    return { to: { name: productToAccess.value ? productToAccess.value?.route : "pricing" } }
  })

  const ctaLabel = computed(() => {
    if (!user.isAuthenticated.value) {
      return t("common.cta_label.guest")
    }

    return productToAccess.value
      ? t(
          `common.cta_label.subscribed.${productToAccess.value?.productId}_${productToAccess.value?.planCode}`
        )
      : t("common.cta_label.no_subscription")
  })

  const cta = reactiveComputed(() => {
    return {
      label: ctaLabel.value,
      action: heroCtaAction.value,
      hasProductToAccess: user.isAuthenticated.value && productToAccess.value?.productId,
    }
  })

  return cta
}
