<template>
  <section class="bg-neutral-100 py-10" aria-labelledby="newsroom-heading">
    <div class="container grid grid-cols-3 gap-5 md:flex md:flex-col md:gap-10">
      <LandingHomeSectionBlogHeader
        header="Newsroom"
        title="FINQING OUT LOUD"
        seeAllHref="https://press.finqai.com/"
        labelledby="newsroom-heading"
      />

      <SwiperList
        class="col-span-2"
        :list="articles"
        :item-width="{ default: 387, mobile: 250 }"
        :enable="isMobile"
      >
        <template #item="{ item, className }">
          <LandingHomeSectionNewsroomCard :article="item" :class="className" />
        </template>
      </SwiperList>
    </div>
  </section>
</template>

<script setup lang="ts">
export interface NewsroomArticle {
  date: string
  title: string
  site: string
  href: string
  image: string
}

const { isMobile } = useDisplay()

const articles: NewsroomArticle[] = [
  {
    date: "March 5, 2024",
    title: "The AI That Beats the S&P 500: In Conversation with FINQ CEO & Founder Eldad Tamir",
    site: "Benzinga",
    href: "https://www.benzinga.com/content/37481192/the-ai-that-beats-the-s-p-500-in-conversation-with-finq-ceo-founder-eldad-tamir",
    image: "homepage/article-1.png",
  },
  {
    date: "January 23, 2024",
    title: "Democratizing Finance: The Rise of AI-Powered Investing with FINQ",
    site: "Los Angeles Wire",
    href: "https://lawire.com/democratizing-finance-the-rise-of-ai-powered-investing-with-finq/",
    image: "homepage/article-2.png",
  },
]
</script>

<style lang="scss" scoped>
.article-card {
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 5px 12px -2px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow:
      0px 0px 0px 1px rgba(0, 0, 0, 0.01),
      0px 5px 12px -2px rgba(0, 0, 0, 0.1);
  }
}

.swiper-card {
  min-width: 250px;
  width: 100%;
  max-width: 387px;
  @screen md {
    max-width: 250px;
  }
}
</style>
