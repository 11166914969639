<script setup lang="ts">
const scrubber = ref()
const scrubbing = ref(false)
const pendingValue = ref(0)
const { isMobile } = useDisplay()

const scrubbingEvents = computed(() => {
  if (isMobile.value) {
    return {
      onTouchstart: () => (scrubbing.value = true),
    }
  } else {
    return {
      onMousedown: () => (scrubbing.value = true),
    }
  }
})

watch(
  () => isMobile.value,
  (mobile) => {
    if (mobile) {
      useEventListener("touchend", () => (scrubbing.value = false))
    } else {
      useEventListener("mouseup", () => (scrubbing.value = false))
    }
  },
  { immediate: true }
)

const emit = defineEmits(["update:modelValue"])
const props = withDefaults(
  defineProps<{
    min?: number
    max: number
    endBuffer: number
    modelValue: number
    waiting: boolean
  }>(),
  { min: 0, max: 100, endBuffer: 0 }
)

const value = defineModel<number>({ default: 0 })
const { elementX, elementWidth } = useMouseInElement(scrubber)

watch([scrubbing, elementX], () => {
  const progress = Math.max(0, Math.min(1, elementX.value / elementWidth.value))
  pendingValue.value = progress * props.max

  if (scrubbing.value) value.value = pendingValue.value
})
</script>

<template>
  <div
    class="z-2 absolute inset-x-2 bottom-2 h-4 rounded-full bg-white/30"
    v-bind="scrubbingEvents"
    ref="scrubber"
  >
    <div class="relative h-full w-full overflow-hidden rounded-full">
      <div
        class="absolute left-0 top-0 h-full w-full bg-white opacity-30"
        :style="{ transform: `translateX(${(endBuffer / max) * 100 - 100}%)` }"
      />
      <div
        class="relative h-full w-full bg-white/80"
        :style="{ transform: `translateX(${(value / max) * 100 - 100}%)` }"
      />
    </div>
    <div class="absolute inset-0 opacity-0 hover:opacity-100" :class="{ '!opacity-100': scrubbing }">
      <slot :pendingValue="pendingValue" :position="`${Math.max(0, Math.min(elementX, elementWidth))}px`" />
    </div>
  </div>
</template>
