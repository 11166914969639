<template>
  <button :class="classNames.root" @click="flip">
    <div
      class="flip-card-inner group relative h-full w-full text-center transition-transform duration-1000"
      :class="!isMobile && { 'rotate-y-180': flipped }"
      @transitionstart.self="() => (!isFlipping ? (isFlipping = true) : null)"
      @transitionend.self="() => (isFlipping ? (isFlipping = false) : null)"
    >
      <div :class="classNames.front">
        <slot name="front" :flipped="false" :flip />

        <ExpandOrCloseButton :flipped="false" :class="classNames.action.front" />
      </div>

      <div v-if="isMounted && !isMobile" :class="classNames.back">
        <slot name="back" :flipped="true" :flip />
        <ExpandOrCloseButton :flipped="true" :class="classNames.action.back" />
      </div>

      <UiDialogBasic
        v-if="isMobile"
        v-model:open="flipped"
        hide-close-button
        :classes="{ panel: classNames.modal }"
      >
        <UiDialogTitle class="sr-only">Dialog</UiDialogTitle>
        <ExpandOrCloseButton
          :flipped="true"
          :isMobileModal="true"
          :class="classNames.action.modal"
          @click="flip"
        />
        <slot name="back" :flipped="true" :flip />
      </UiDialogBasic>
    </div>
  </button>
</template>

<script setup lang="tsx">
import type { HTMLAttributes } from "vue"

import { UiButton } from "#components"
import { LucidePlus } from "lucide-vue-next"

interface UiFlipCardClasses {
  root: ClassValue
  front: ClassValue
  back: ClassValue
  modal: ClassValue
  action: Partial<{
    root: ClassValue
    front: ClassValue
    back: ClassValue
    modal: ClassValue
  }>
}

const props = defineProps<{ class?: ClassValue; classes?: Partial<UiFlipCardClasses> }>()

const flipped = ref(false)
const isFlipping = ref(false)
const isMounted = useMounted()
const { isMobile } = useDisplay()
const { tbool } = useI18nUtils({ useScope: "global" })

function flip() {
  if (isFlipping.value) return
  flipped.value = !flipped.value
}

defineExpose({ flipped, flip })

watch(isMobile, () => (flipped.value = false))

const classNames = computed(
  (): UiFlipCardClasses => ({
    root: cn(
      "flip-card h-48 w-72 bg-transparent",
      { flipped: !isMobile.value && flipped.value },
      props.classes?.root,
      props.class
    ),
    front: cn("flip-card-front backface-hidden absolute h-full w-full", props.classes?.front),
    back: cn(
      "flip-card-back backface-hidden rotate-y-180 absolute h-full w-full transform",
      props.classes?.back
    ),
    modal: cn("p-0 pt-16", props.classes?.modal),
    action: {
      front: cn(props.classes?.action?.root, props.classes?.action?.front),
      back: cn(props.classes?.action?.root, props.classes?.action?.back),
      modal: cn(props.classes?.action?.root, props.classes?.action?.modal),
    },
  })
)

const ExpandOrCloseButton: FunctionalComponent<
  { class?: ClassValue; isMobileModal?: boolean; flipped?: boolean } & HTMLAttributes
> = ({ class: className, isMobileModal, flipped, ...rest }) => {
  return (
    <UiButton
      class={cn(
        "absolute bottom-5 end-5",
        "size-9 rounded-full bg-white/30 text-white/60 hover:bg-white/40 hover:text-white group-hover:bg-white/40",
        { "bottom-[unset] end-[unset] start-5 top-5 size-7": isMobileModal },
        className
      )}
      variant="icon"
      is="div"
      {...(rest as unknown as HTMLButtonElement)}
    >
      <span class="sr-only">{tbool("common.expand", !!flipped)}</span>
      <LucidePlus
        size={22}
        class={cn("transition-transform delay-500 duration-1000", { "rotate-45": flipped })}
      />
    </UiButton>
  )
}
</script>

<style lang="scss" scoped>
.flip-card {
  perspective: 2000px;
}

.flip-card-inner {
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}
</style>
