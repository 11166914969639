<template>
  <section :class="cn('container', props.class)" aria-labelledby="get-started-heading">
    <h4 class="text-2.5xl/8 mb-6 whitespace-pre-line font-semibold" id="get-started-heading">
      {{ t("title") }}
    </h4>

    <UiButton
      class="h-m w-fit py-0 text-sm"
      :to="{ name: 'pricing' }"
      :id="genClickId('index', 'getStarted', 'button')"
      rounded="full"
      size="sm"
    >
      {{ t("cta_text") }}
    </UiButton>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{ class?: ClassValue }>()
const emit = defineEmits(["click:getStarted"])
const { t } = useI18n({ useScope: "local" })
</script>

<i18n lang="json">
{
  "en": {
    "title": "For every financial problem.\nFor everyone.",
    "cta_text": "Choose your plan"
  },
  "he": {
    "title": "לכל מוצר פיננסי.\n לכל אחד/ת.",
    "cta_text": "בחר/י את התוכנית המתאימה לך"
  }
}
</i18n>
