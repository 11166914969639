<template>
  <div :class="cn('flex items-center gap-5 md:flex-col', props.class)">
    <UiFlipCard
      class="flip-card-height relative w-full rounded-lg"
      :classes="{
        action: { modal: 'bg-black/30 text-white hover:bg-black/40' },
      }"
      :key="debouncedLocale"
    >
      <template #front>
        <ScienceBasedCard>
          <h3
            class="start-finqing-gradient start-finqing-gradient-text-settings whitespace-pre-line text-center text-3xl/10 font-semibold"
          >
            {{ t("flip_cards.science_based.front.title") }}
          </h3>
        </ScienceBasedCard>
      </template>

      <template #back="{ flip }">
        <ScienceBasedCard :flipped="true" @flip="flip">
          <p :class="cn('px-10 text-start text-sm/5 font-light text-white/90 md:px-0 md:text-black/75')">
            {{ t("flip_cards.science_based.back.description") }}
          </p>
        </ScienceBasedCard>
      </template>
    </UiFlipCard>

    <UiFlipCard
      class="flip-card-height relative w-full rounded-lg"
      :classes="{
        action: { root: 'bg-black/30 text-white hover:bg-black/40 group-hover:bg-black/40' },
      }"
    >
      <template #front>
        <FINQRankCard>
          <LazyLandingHomeDataCardFINQRank />
        </FINQRankCard>
      </template>

      <template #back="{ flip }">
        <FINQRankCard :flipped="true" @flip="flip">
          <p class="px-10 text-start text-sm/5 font-light text-black/75 md:px-0">
            {{ t("flip_cards.finq_rank.back.description") }}
          </p>
        </FINQRankCard>
      </template>
    </UiFlipCard>
  </div>
</template>

<script setup lang="tsx">
import type { HTMLAttributes } from "vue"

const props = defineProps<{
  class?: ClassValue
}>()

const { isMobile } = useDisplay()
const { t, locale } = useI18n({ useScope: "local" })
const debouncedLocale = useDebounce(locale, 200)

const ScienceBasedCard: FunctionalComponent<
  { class?: ClassValue; flipped?: boolean },
  { flip: () => void }
> = ({ class: className, flipped, ...rest }, { slots, emit }) => {
  const isMobileModal = isMobile.value && flipped
  return (
    <div
      class={cn(
        "flip-card-box-shadow grid w-full place-content-center overflow-hidden p-5 md:px-11 md:pb-10",
        { "place-content-start gap-6": isMobileModal, "flip-card-height": !isMobileModal },
        className
      )}
      {...rest}
    >
      <span
        class={cn("text-white/80", {
          "absolute start-5 top-5": !isMobileModal,
          "text-black/50": isMobileModal,
        })}
      >
        FINQ-AI
      </span>

      {slots.default ? slots.default() : null}

      <div
        class={cn("-z-1 absolute inset-0 flex overflow-hidden rounded-lg", {
          "md:hidden": isMobileModal,
        })}
        key={locale.value}
      >
        <div class="z-1 size-full flex-1 rounded-lg bg-gradient-to-b from-black/50 to-black/80 to-60%"></div>
        <div class="start-finqing-gradient absolute inset-0 scale-150 rounded-lg blur-2xl"></div>
      </div>
    </div>
  )
}

const FINQRankCard: FunctionalComponent<
  { class?: ClassValue; flipped?: boolean } & HTMLAttributes,
  { flip: () => void }
> = ({ class: className, flipped, ...rest }, { slots, emit }) => {
  const isMobileModal = isMobile.value && flipped
  return (
    <div
      class={cn(
        "flip-card-box-shadow grid w-full place-content-center rounded-lg p-5 md:px-5 md:pb-10",
        { "h-fit gap-6 md:px-11": isMobileModal, "flip-card-height": !isMobileModal },
        className
      )}
      {...rest}
    >
      <span class={cn("text-black/50", { "absolute start-5 top-5": !isMobileModal })}>FINQ RANK</span>

      {slots.default ? slots.default() : null}
    </div>
  )
}
</script>

<style lang="scss" scoped>
.flip-card-box-shadow {
  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.01),
    0px 8px 12px -4px rgba(0, 0, 0, 0.02),
    0px 16px 48px -8px rgba(0, 0, 0, 0.03);
}

.flip-card-height {
  height: 350px;
}
</style>

<i18n lang="json">
{
  "he": {
    "flip_cards": {
      "science_based": {
        "front": {
          "title": "השקעות\nמבוססות מדע"
        },
        "back": {
          "description": "בעולם של היום, שבו אינסוף נתונים ומגוון דעות, הדרך היחידה להפריד את המוץ מהתבן ולקבל הערכה ריאלית של מוצר פיננסי היא להסתמך על שיטות ניתוח מבוססות מדע. אימוץ מודלים מבוססי בינה מלאכותית והחלתם על הכמות העצומה של הנתונים הזמינים כיום מאפשרים ל-FINQ לייצר תובנות פיננסיות שהוכיחו כי הן מנצחות את המדדים המובילים בעולם, ובשורה התחתונה מגדילות את תשואות ההשקעה של לקוחותינו."
        }
      },
      "finq_rank": {
        "front": {
          "title": "דירוג FINQ"
        },
        "back": {
          "description": "FINQ rank הוא ערך מספרי הניתן לכל מוצר פיננסי ומתקבל באמצעות גישה מבוססת מדע לעיבוד נתונים פיננסיים. הדירוג נוצר באמצעות מנוע מבוסס בינה מלאכותית אשר אוסף תחילה נתונים ממקורות שונים כגון מומחים לשוק ההון, חוכמת ההמונים ונתונים פיננסיים של כל חברה. לאחר מכן, הוא מקלף את העטיפות השיווקיות של כל מוצר, מפרק אותו לנכסיו הבודדים, מסיר מידע לא רלוונטי, ומעבד כל פיסת מידע של כל נכס לפי המשמעות הפיננסית שלה. לבסוף, בהתבסס על מודלים תיאורטיים של הערכת שווי נכסים, המנוע משקלל את כל הנתונים על מנת לספק דירוג יחסי לכל מוצר המשקף את הסיכון הריאלי מול התשואה הפוטנציאלית שלו."
        }
      }
    }
  },
  "en": {
    "flip_cards": {
      "science_based": {
        "front": {
          "title": "Science-based\ninvestment"
        },
        "back": {
          "description": "In today’s world, where there’s an ocean of data and tons of opinions, the only way to sort the wheat from the chaff and get a realistic estimation of a financial product is to rely on science-based methods of analysis. Embracing AI-based models and applying them to the vast amount of data available today allows FINQ to gain financial insights that have proven to beat the world’s leading indices and ultimately boost the investment returns of our customers."
        }
      },
      "finq_rank": {
        "front": {},
        "back": {
          "description": "FINQ rank is a numerical value given to every financial product obtained through a science-based approach to processing financial data. The rank is created using an AI-based engine, which first collects data from multiple sources, such as insights from experts, crowd wisdom data, and financial fundamentals. Next, it peels off every product’s marketing wraps, breaks it down into its single assets, strips away irrelevant information, and factors every data point of every single asset according to its financial meaning. Then, based on theoretical models of asset valuation, the engine ultimately integrates all the data to provide a relative rank for every product, which reflects its realistic risk vs. potential return value."
        }
      }
    }
  }
}
</i18n>
