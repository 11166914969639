<template>
  <PricingManager>
    <section :class="cn('container flex flex-col gap-10 md:gap-5', props.class)">
      <UiSegmentedControl
        class="w-fit"
        v-model="selectedServiceId"
        :items="mappedServices"
        zag-id="services"
        :classes="{
          root: 'p-[3px] md:w-fit',
          item: 'min-h-7 min-w-0 md:w-fit',
          indicator: 'border',
        }"
      />

      <LandingHomeSectionServicesSelected :service="selectedService" />
    </section>
  </PricingManager>
</template>

<script setup lang="ts">
import { PricingProductEnum } from "#imports"

import { UiSegmentedControlItem } from "@finq/ui/components/ui/SegmentedControl.vue"

const props = defineProps<{ class?: ClassValue }>()
const { tmsafe: ptmsafe } = useI18nUtils({ useScope: "parent" })

const allServices = computed(() => {
  return Object.values(ptmsafe("products")) as MappedProduct[PricingProductEnum][]
})

const mappedServices = computed((): UiSegmentedControlItem[] => {
  return allServices.value
    .map((item: any) => ({ label: item.label, value: item.id }))
    .sort((a, b) => productsOrder.indexOf(a.value) - productsOrder.indexOf(b.value))
})

const productsOrder: PricingProductEnum[] = [
  PricingProductEnum.Pensions,
  PricingProductEnum.Stocks,
  PricingProductEnum.Funds,
]

const selectedServiceId = ref(mappedServices.value?.[0].value || productsOrder[0])

const selectedService = computed(() => {
  return allServices.value.find((item) => item.id == selectedServiceId.value) || allServices.value?.[0]
})
</script>

<style lang="scss">
.shadow-below {
  &:not(:first-child) {
    --shadow-direction: 1;
    clip-path: inset(0px -80px);
    box-shadow:
      calc(1px * var(--shadow-direction)) 0px 1px 0px rgba(0, 0, 0, 0.02),
      calc(2px * var(--shadow-direction)) 0px 2px 0px rgba(0, 0, 0, 0.02),
      calc(4px * var(--shadow-direction)) 0px 4px 0px rgba(0, 0, 0, 0.05),
      calc(8px * var(--shadow-direction)) 0px 8px 0px rgba(0, 0, 0, 0.07),
      calc(14px * var(--shadow-direction)) 0px 14px 0px rgba(0, 0, 0, 0.07);
  }
}
.app-ltr .reversed-shadow-below {
  --shadow-direction: -1;
}

.shadow-below-small:not(:first-child) {
  --shadow-direction: 1;
  clip-path: inset(0px -80px);
  box-shadow:
    0px 0px 3px 0px rgba(0, 0, 0, 0.08),
    calc(3px * var(--shadow-direction)) 0px 8px 0px rgba(0, 0, 0, 0.06);
}
.app-ltr .reversed-shadow-below-small {
  --shadow-direction: -1;
}
</style>
