<template>
  <div :class="classNames.root">
    <div v-if="renderFallback" :class="classNames.fallback.root">
      <template v-for="(item, index) in list" :key="index">
        <slot name="item" :item :index :className="classNames.fallback.item" />
      </template>
    </div>

    <swiper-container v-else v-bind="swiperOptions" ref="swiperRef" :id :class="classNames.swiper.root">
      <swiper-slide v-for="(item, index) in list" :class="classNames.swiper.item" :key="index">
        <slot name="item" :item :index className="" />
      </swiper-slide>
    </swiper-container>

    <slot />
  </div>
</template>

<script setup lang="ts" generic="ListType extends any">
// SwiperList
import { register } from "swiper/element/bundle"
import type { Swiper, SwiperOptions } from "swiper/types"

import { AppBreakpoints } from "@finq/app-base/lib/configs"

export interface SwiperListClasses {
  root: ClassValue
  fallback: Partial<{
    root: ClassValue
    item: ClassValue
  }>
  swiper: Partial<{
    root: ClassValue
    item: ClassValue
  }>
}

export interface SwiperListProps<T> {
  list: T[]
  options?: SwiperOptions
  class?: ClassValue
  classes?: Partial<SwiperListClasses>
  gap?: `gap-${number}`
  itemWidth: string | number | { default: string | number; mobile: string | number }
  /** Activates the swiper, defaults to `isMounted` */
  enable?: undefined | boolean
}

register()

const props = withDefaults(defineProps<SwiperListProps<ListType>>(), { gap: "gap-5", enable: undefined })
const emit = defineEmits<{ (event: "slideChange", value: Swiper): void }>()

const isMounted = useMounted()
const id = useId() + "-swiper-list"
const swiperRef = ref<{ swiper: Swiper } | null>(null)
const renderFallback = computed(() => (props.enable !== undefined ? !props.enable : !isMounted.value))
const { direction } = useRtl()

// Emit slideChange event
watchEffect(() => {
  if (!swiperRef.value?.swiper) return
  swiperRef.value.swiper.on("slideChange", (v) => emit("slideChange", v))
})

// Auto adjust swiper according to the direction
watch(direction, () => {
  if (!swiperRef.value?.swiper) return
  swiperRef.value.swiper.changeLanguageDirection(direction.value)
})

const gapValue = computed(() => {
  if (!props.gap) return 20
  return parseInt(props.gap.split("-")[1]) * 4
})

const swiperOptions = computed(
  (): SwiperOptions => ({
    spaceBetween: gapValue.value,
    initialSlide: 0,
    slidesPerView: "auto",
    autoHeight: false,
    keyboard: { enabled: true },
    ...props.options,

    breakpoints: {
      ...props.options?.breakpoints,

      0: {
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        grabCursor: true,
        ...(props.options?.breakpoints?.[0] ?? {}),
      },

      [AppBreakpoints.md]: {
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        ...(props.options?.breakpoints?.[AppBreakpoints.md] ?? {}),
      },
    },
  })
)

const classNames = computed(
  (): SwiperListClasses => ({
    root: cn("md:w-plus-10 md:-mx-5", props.classes?.root, props.class),
    fallback: {
      root: cn(
        ["flex w-full md:ps-5", { "overflow-hidden": !isMounted.value }],
        props.gap,
        props.classes?.fallback?.root
      ),
      item: cn("swiper-card", props.classes?.fallback?.item),
    },
    swiper: {
      root: cn("-mb-4 w-full", props.classes?.swiper?.root),
      item: cn("swiper-card mb-4", props.classes?.swiper?.item),
    },
  })
)

const desktopWidth = computed(() =>
  toUnit((typeof props.itemWidth === "object" ? props.itemWidth.default : props.itemWidth) || 400)
)
const mobileWidth = computed(() =>
  toUnit((typeof props.itemWidth === "object" ? props.itemWidth.mobile : props.itemWidth) || 250)
)

defineExpose({ swiperRef, id, isRendered: renderFallback })
</script>

<style lang="scss" scoped>
:deep(.swiper-card) {
  min-width: v-bind(desktopWidth);
  max-width: v-bind(desktopWidth);
  @screen md {
    min-width: v-bind(mobileWidth);
    max-width: v-bind(mobileWidth);
  }
}
</style>
