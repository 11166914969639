<template>
  <span
    :class="
      cn(
        'relative inline-flex h-7 items-center justify-center rounded-full border px-4 text-center align-top text-base font-semibold text-white transition-all duration-1000',
        props.class
      )
    "
  >
    <div class="pointer-events-none absolute inset-0 z-0 flex">
      <UiTransition name="fade" mode="in-out" duration="duration-500">
        <div
          class="absolute inset-0 rounded-full"
          :style="chipVariantsStyle({ color: props.color })"
          role="image"
          :key="color!"
        ></div>
      </UiTransition>
    </div>

    <span class="z-1">
      <slot>
        <UiTransition name="scrollY" duration="duration-500" :delay="{ enter: 'delay-75' }" mode="out-in">
          <span class="block" :key="props.text">
            {{ props.text }}
          </span>
        </UiTransition>
      </slot>
    </span>
  </span>
</template>

<script lang="ts">
export interface ChipProps {
  class?: ClassValue
  color?: StyleVariantProps<typeof chipVariantsStyle>["color"]
  text?: string
}

export const chipVariantsStyle = sva({
  defaultVariants: { color: "blue" },

  base: {
    background: "linear-gradient(98deg, #34B4F4 0%, #3D99F5 100%)",
  },

  variants: {
    color: {
      blue: {
        background: "linear-gradient(98deg, #34B4F4 0%, #3D99F5 100%)",
      },
      purple: {
        background: "linear-gradient(98deg, #9A8FFF 0%, #8B6FFB 100%)",
      },
      orange: {
        background: "linear-gradient(98deg, #FFB200 0%, #FFA114 100%)",
      },
      green: {
        background: "linear-gradient(98deg, #74E665 0%, #59E34F 100%)",
      },
      emerald: {
        background: "linear-gradient(98deg, #47D794 0%, #33CC8C 100%)",
      },
      cyan: {
        background: "linear-gradient(98deg, #29B9D6 0%, #27AACE 100%)",
      },
      "dark-blue": {
        background: "linear-gradient(98deg, #4D94E5 0%, #387BE0 100%)",
      },
      "dark-purple": {
        background: "linear-gradient(98deg, #9F63E9 0%, #914BE6 100%)",
      },
      pink: {
        background: "linear-gradient(98deg, #D9A9F4 0%, #D69CFC 100%)",
      },
    },
  },
})
</script>

<script setup lang="ts">
import { type StyleVariantProps, sva } from "style-variance-authority"

const props = withDefaults(defineProps<ChipProps>(), { color: "blue" })
</script>

<style lang="scss">
.chip-gradient-properties {
  @property --base-color {
    syntax: "<color>";
    initial-value: #34b4f4;
    inherits: false;
  }

  @property --base-color-light {
    syntax: "<color>";
    initial-value: #3d99f5;
    inherits: false;
  }
}
</style>
